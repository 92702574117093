<template>
  <div class="delete">
    <!-- top -->
    <el-row class="df-row" :gutter="10">
      <el-col :span="3">
        <el-input
          placeholder="请输入姓名/手机号码"
          v-model="submitData.Word"
          size="mini"
        >
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="mini" @click="onSearch">
          搜索
        </el-button>
      </el-col>
    </el-row>
    <!-- end top -->
    <!-- table height="calc(100vh - 260px)"-->
    <el-table
      :data="dataList"
      style="width: 100%"
      v-loading="loading"
      :max-height="tableMaxHeight"
    >
      <el-table-column type="selection" width="48"> </el-table-column>
      <el-table-column label="姓名" width="250" fixed="left">
        <template slot-scope="scope">
          <div class="member-name">
            <div class="member-name-rigth">
              <el-image :src="scope.row.MemberHead">
                <div
                  slot="error"
                  style="
                    height: 100%;
                    width: 100%;
                    background-color: #c8c5c8;
                    border-radius: 5px;
                  "
                >
                  <div
                    style="
                      height: 40px;
                      line-height: 40px;
                      text-align: center;
                      font-weight: bold;
                      font-size: 18px;
                    "
                  >
                    {{ scope.row.MemberName.substring(0, 1) }}
                  </div>
                  <el-tag
                    type="warning"
                    size="mini"
                    style="width: 100%; text-align: center"
                    >{{ scope.row.LossType }}</el-tag
                  >
                </div>
              </el-image>
            </div>
            <div>
              <div>
                <span>{{ scope.row.MemberName }}</span>
                <i
                  :class="
                    scope.row.MemberSex == 1 ? 'el-icon-male' : 'el-icon-female'
                  "
                  style="margin-left: 5px; font-size: 15px; font-weight: bold"
                ></i>
              </div>
              <div class="size">{{ scope.row.MemberPhone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <!--  -->
      <el-table-column
        v-for="(item, index) in headList"
        :key="index"
        :label="item.Name"
        :prop="item.DataKey"
        width="180"
      >
        <template slot-scope="scope">
          <div>
            <div v-if="item.DataKey == 'Birthday'">
              {{
                scope.row[item.DataKey]
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row[item.DataKey] * 1000)
                    )
                  : ""
              }}
            </div>
            <div v-else-if="item.DataKey == 'MemberTags'">
              <el-tag
                v-for="(tagItem, tagIndex) in scope.row[item.DataKey]"
                :key="'tag' + tagIndex"
                size="small"
              >
                {{ tagItem }}
              </el-tag>
            </div>
            <div v-else-if="item.DataKey == 'VipInfos'">
              <el-popover placement="bottom" width="400" trigger="click">
                <div class="member-card">
                  <div
                    class="member-card-list"
                    v-for="(vipItem, vipIndex) in scope.row[item.DataKey]"
                    :key="'vip' + vipIndex"
                  >
                    <div class="title">
                      <span>{{ vipItem.CardName }}</span>
                      <span>（{{ vipItem.VipNo }}）</span>
                    </div>
                    <ul class="grid">
                      <li>
                        <p>充值余额</p>
                        <p>{{ vipItem.KeepBalance }}</p>
                      </li>
                      <li>
                        <p>赠送金额</p>
                        <p>{{ vipItem.DonaBalance }}</p>
                      </li>
                      <li>
                        <p>卡的积分</p>
                        <p>{{ vipItem.InteBalance }}</p>
                      </li>
                      <li>
                        <p>卡的欠款</p>
                        <p>{{ vipItem.DebtBalance }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <span
                  v-if="scope.row[item.DataKey][0]"
                  slot="reference"
                  class="look-card"
                >
                  <span>{{ scope.row[item.DataKey][0].CardName }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </span>
              </el-popover>
            </div>
            <div v-else-if="item.DataKey == 'LastExpeTime'">
              {{
                scope.row[item.DataKey]
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row[item.DataKey] * 1000)
                    )
                  : ""
              }}
            </div>
            <div v-else-if="item.DataKey == 'RegisterDate'">
              {{
                scope.row[item.DataKey]
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row[item.DataKey] * 1000)
                    )
                  : ""
              }}
            </div>
            <div v-else>
              {{ scope.row[item.DataKey] }}
            </div>
          </div>
        </template>
      </el-table-column>
      <!--  -->
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            :disabled="restoreDisabled"
            @click="onRestore(scope.row)"
          >
            恢复
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end table -->

    <!--pagination-->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="submitData.PageSize"
      :current-page="submitData.PageIndex"
      :total="submitData.TotalRecord"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!-- end pagination-->
  </div>
</template>

<script>
import api from "@/api/member.js";
export default {
  data() {
    return {
      dataList: [],
      headList: [],
      submitData: {
        VipNo: "",
        MemberName: "",
        MemberPhone: "",
        MemberKind: "",
        MemberState: 2,
        MemberSex: -1,
        StoresShare: -1,
        MemberFollow: "",
        SourceWay: -1,
        LossLevel: 0,
        MemberTag: "",
        IntroduceGuid: "",
        StarBirthday: "",
        EndBirthday: "",
        StartAge: 0,
        EndAge: 0,
        StartExpeFreq: 0,
        EndExpeFreq: 0,
        StartLastExpeAway: 0,
        EndLastExpeAway: 0,
        StartLastExpeDate: "",
        EndLastExpeDate: "",
        PageSize: 10,
        PageIndex: 1,
        TotalRecord: 0,
        Word: "",
      },
      loading: false,
      restoreDisabled: false, // 恢复按钮禁用
      tableMaxHeight: "",
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 监听窗口变化实时获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 260;
    },

    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.getMemberMaintList(this.submitData);
        let { filter, result } = data;
        let { DataList, HeadList } = result;
        this.dataList = DataList;
        this.headList = HeadList;
        this.submitData = filter;
      } catch (e) {}
      this.loading = false;
    },

    onSearch() {
      this.initPageData();
    },

    handleSizeChange(event) {
      this.submitData.PageSize = event;
      this.submitData.PageIndex = 1;
      this.initPageData();
    },

    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.initPageData();
    },

    onRestore(event) {
      this.restoreDisabled = true;
      this.$confirm(`是否恢复该‘${event.MemberName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.restoreMember({
              member_guid: event.MemberGuid,
            });
            this.$message({
              message: "恢复成功!",
              type: "success",
            });
            this.initPageData();
          } catch (e) {
            console.log(e);
          }
        })
        .finally(() => {
          this.restoreDisabled = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.delete {
  .df-row {
    margin: 20px 0;
  }

  .member-name {
    display: flex;
    align-items: center;
    .member-name-rigth {
      margin-right: 10px;
      .el-image {
        height: 60px;
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .el-icon-female {
      color: tomato;
    }

    .el-icon-male {
      color: #409eff;
    }
  }

  .el-pagination {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}
</style>